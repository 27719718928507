@import "../../constants/scss/vars.scss";


.modal-content {
  .notif-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: $dark-blue;
    margin-bottom: 72px;
  }

  .notif-picto {
    width: 160px;
    height: 160px;
    border-radius: 160px;
    background-color: white;
    margin: 0 auto 128px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      max-width: 57px;
    }
  }

  .notif-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: flex-end;
    margin: auto;
    text-align: center;
    color: $dark-blue;
    padding: 0 55px;
    margin-bottom: 70px;
  }
}

.success-modal, .error-modal {
  .modal-content {
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;;
    align-items: center;
    border-radius: 48px 48px 0 0;
  }
}

.success-modal {
  .notif-picto {
    background-color: $green;
  }
}

.error-modal {
  .notif-picto {
    background-color: $red;
  }
}
