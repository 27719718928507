@import "./constants/scss/vars.scss";

.button-block {
  background: white;
  margin: 16px 0;
  height: 72px;
  padding: 10px;
  border-radius: 72px;
  color: $dark-blue;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;

  &.button-black {
    background: $dark-blue;
    border: 1px solid white;
    color: white;
  }

  &.button-block {
    width: 100%;
  }

  &.button-green {
    background: $green;
    border: 1px solid white;
    color: white;
  }

  &.button-red {
    background: $red;
    border: 1px solid white;
    color: white;
  }

  &.button-disabled {
    background: $dark-blue;
    opacity: 0.2;
    color: white;
  }

  &.button-invisible {
    background: transparent;
    border: none !important;
    color: $dark-blue;
    text-decoration: underline !important;
    font-weight: 600;
}
  &.button-borderless {
    border: none;
  }

  img {
    margin-left: 20px;
    max-height: 20px;
    max-width: 20px;
  }

  &.icon-reverse {
    flex-direction: row-reverse;

    img {
      margin: 0 20px 0 0;
    }
  }

  &.black-border {
    border: 1px solid $dark-blue;
  }
}
