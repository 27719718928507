*, *:before, *:after {
  box-sizing: border-box;
}

html, body, #root, .App, .App-header { height: 100%; }

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  background-color: white;
  color: #1F2942;
  margin:0px;
}
